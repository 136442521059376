import Image from 'next/image';
import LoginArt from 'images/login-art.svg';
import styles from './LoginLayout.module.css';

interface Properties {
  children: React.ReactNode;
}

export const LoginLayout = ({ children }: Properties) => (
  <div className={styles.container}>
    <main className={styles.main}>
      <div className={styles.content}>{children}</div>
    </main>

    <footer className={styles.footer}>
      <div className={styles.img}>
        <Image layout="fill" alt="logo" src={LoginArt} />
      </div>
    </footer>
  </div>
);
