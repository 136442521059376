import Image from 'next/image';
import styles from './Brand.module.css';
import VisserLogo from 'images/visser.svg';

export const Brand = () => (
  <div id="brand" className={styles.container}>
    <Image alt="logo" src={VisserLogo} />
    <span className={styles.text}>contactlenzen</span>
  </div>
);
