import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line no-restricted-imports
import { IconDefinition } from '@fortawesome/pro-light-svg-icons/index';
import classnames from 'classnames';

import styles from './Input.module.css';

interface Properties extends React.HTMLProps<HTMLInputElement> {
  label: string;
  icon?: IconDefinition;
  error?: boolean | undefined;
}

export const Input = ({ id, label, icon, className, error = false, ...properties }: Properties) => (
  <div className={classnames(styles.container, className, { [styles.error]: error })}>
    <input name={id} id={id} className={styles.input} placeholder=" " {...properties} />
    <label htmlFor={id} className={styles.label}>
      {label}
    </label>
    {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} /> : undefined}
  </div>
);
