import React from 'react';
import styles from './InputContainer.module.css';

interface Properties {
  label?: string;
  children: React.ReactNode;
}

export const InputContainer = ({ label, children }: Properties) => (
  <div className={styles.container}>
    {!!label && <span>{label}</span>}
    {children}
  </div>
);
