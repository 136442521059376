import type { NextPage } from 'next';
import Link from 'next/link';
import Head from 'next/head';

import { Brand } from 'components/Brand';
import { LoginLayout } from 'components/layouts/LoginLayout';
import helperStyles from 'styles/helper-styles.module.css';
import { Pages } from 'constants/pages';

import styles from './inloggen.module.css';
import { LoginForm } from 'components/forms/LoginForm';
import { useUser } from 'providers/UserProvider';
import { Loader } from 'components/Loader';
import { Fragment, useEffect } from 'react';
import { RichText } from 'components/RichText';
import { useRouter } from 'next/router';

const Login: NextPage = () => {
  const { user, isLoadingUser } = useUser();

  const router = useRouter();

  useEffect(() => {
    if (!!user && !isLoadingUser) router.push(Pages.Orders);
  }, [isLoadingUser, router, user]);

  return (
    <LoginLayout>
      {isLoadingUser ? (
        <Fragment>
          <RichText>
            <p>U wordt doorverwezen...</p>
          </RichText>
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <Head>
            <title>Inloggen - Visser contactlenzen</title>
          </Head>
          <Brand />
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>Inloggen</h1>
            <p>Gebruik uw e-mailadres en wachtwoord om in te loggen.</p>
          </div>
          <LoginForm />
          <p className={helperStyles.centerOnMobile}>
            Nog geen account? <Link href={Pages.Register}>Maak een account</Link>
          </p>
        </Fragment>
      )}
    </LoginLayout>
  );
};

export default Login;
