import { ApiError } from 'helpers/api';
import { captureException } from 'helpers/captureException';

const DEFAULT_MESSAGE = 'Er ging iets mis, probeer het nog eens.';

export function handleFormError(error: unknown, onError: (message: string, error: number) => void) {
  if (error instanceof ApiError) {
    if (error.status === 500) {
      captureException(error);
      onError(DEFAULT_MESSAGE, error.status);
    } else if (error.status !== 401) {
      captureException(error);
      onError(error.body.message || error.body.detail || DEFAULT_MESSAGE, error.status);
    } else {
      onError(error.body.message || error.body.detail || DEFAULT_MESSAGE, error.status);
    }
  } else {
    onError(DEFAULT_MESSAGE, -1);
    captureException(error);
  }
}
