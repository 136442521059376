import { ButtonHTMLAttributes, DetailedHTMLProps, HTMLAttributeAnchorTarget } from 'react';
import classnames from 'classnames';
import styles from './Button.module.css';
import { Loader } from 'components/Loader';
import Link, { LinkProps } from 'next/link';

interface Shared {
  label?: string;
  fullWidthOnMobile?: boolean;
  loading?: boolean;
  discrete?: boolean;
  secondary?: boolean;
}

interface ButtonProperties
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    Shared {}

export const Button = ({
  label,
  className,
  fullWidthOnMobile,
  loading,
  type = 'button',
  discrete,
  secondary,
  ...properties
}: ButtonProperties) => (
  <button
    type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
    className={classnames(className, styles.button, {
      [styles.fullWidthOnMobile]: fullWidthOnMobile,
      [styles.discrete]: discrete,
      [styles.secondary]: secondary,
    })}
    disabled={loading}
    {...properties}>
    {loading ? <Loader inverted size="16px" /> : undefined}
    {label}
  </button>
);

interface LinkButtonProperties extends LinkProps, Shared {
  href: string;
  className?: string;
  target?: HTMLAttributeAnchorTarget;
}

export const LinkButton = ({
  label,
  className,
  secondary,
  discrete,
  fullWidthOnMobile,
  ...properties
}: LinkButtonProperties) => (
  <Link className={calculateStyles(className, fullWidthOnMobile, discrete, secondary)} {...properties}>
    {label}
  </Link>
);

const calculateStyles = (
  className: string | undefined,
  fullWidthOnMobile: boolean | undefined,
  discrete: boolean | undefined,
  secondary: boolean | undefined
) =>
  classnames(className, styles.button, {
    [styles.fullWidthOnMobile]: fullWidthOnMobile,
    [styles.discrete]: discrete,
    [styles.secondary]: secondary,
  });
