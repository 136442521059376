export const Login = '/';
export const Register = '/registreren';
export const ForgotPassword = '/wachtwoord-vergeten';
export const ChangePassword = '/wachtwoord-wijzigen';
export const Appointments = '/afspraken';
export const MakeAppointment = '/afspraken/nieuw';
export const AnnualOverview = '/jaaroverzicht';
export const Account = '/account';
export const AccountCreated = '/account-aangemaakt';
export const Subscription = '/abonnement';
export const Orders = '/bestellingen';
export const AccountActivation = '/activeren';
export const RegisterWebshop = './webshop-registreren';
export const ChangePhoneNumber = './telefoonnummer-wijzigen';
export const LoginRedirect = '/login-redirect';

export const Pages = {
  Login,
  Register,
  ForgotPassword,
  ChangePassword,
  Orders,
  Appointments,
  AnnualOverview,
  Account,
  AccountCreated,
  AccountActivation,
  Home: Orders,
  Subscription,
  RegisterWebshop,
  ChangePhoneNumber,
  LoginRedirect,
};
