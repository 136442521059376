import React from 'react';

import styles from './Notification.module.css';
import classNames from 'classnames';

type Properties = {
  children: React.ReactNode;
  type: 'error' | 'success' | 'info';
};

export const Notification = ({ children, type }: Properties) => (
  <div className={classNames(styles.container, styles[type])}>{children}</div>
);
