import { useMemo } from 'react';
import styles from './Loader.module.css';

interface Properties {
  inverted?: boolean;
  size?: string;
}

export const Loader = ({ inverted, size }: Properties) => {
  const classNames = useMemo(() => (inverted ? `${styles.loader} ${styles.inverted}` : styles.loader), [inverted]);
  return <div className={classNames} style={size ? { height: size, width: size } : undefined} />;
};
